require('assets/homepage/_homepage.scss');

import Formatter from 'components/mailbox_formatter'

const defaultHost = document.querySelector('body').getAttribute('data-host')

var form = document.querySelector('form#goToInbox');
form.addEventListener('submit', (event) => {
  event.preventDefault();

  const input = document.querySelector('form#goToInbox input#mailboxName')

  const mbox = input.value
  const placeholder = input.placeholder
  document.location.href = "/app/inbox/" + Formatter.prepare(mbox || placeholder, defaultHost);
});
